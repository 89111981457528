<template>
	<a href="https://quinta-da-maravilhas.amenitiz.io/" target="_blank">
		<slot />
	</a>
</template>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
